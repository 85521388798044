import React, { useEffect } from "react";
import { auth } from "./firebase.js";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Login from "./Pages/auth/login";
import Home from "./Pages/Home/Home.js";
import ReactPlot from "./Pages/ReactPlot/ReactPlot.js";
import Transformer from "./Pages/Transformer/Transformer.js";
import { useUser } from "./UserContext.js";
import Signup from "./Pages/auth/signUp/index.js";
import Heater from "./Pages/Heater/Heater.js";
import PipelineComponent from "./Components/DataTransformation/DataTransformationComponent.js";

const App = () => {
  const { user, setUser } = useUser();
  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged(async (currentUser) => {
      if (currentUser) {
        setUser(currentUser);
      }
    });

    return () => {
      unsubscribe();
    };
  }, []);

  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/login" element={<Login />} />
        <Route path="/signup" element={<Signup />} />
        <Route path="/engine" element={!user ? <Login /> : <ReactPlot />} />
        <Route
          path="/transformer"
          element={!user ? <Login /> : <Transformer />}
        />
        <Route path="/heater" element={!user ? <Login /> : <Heater />} />
        <Route
          path="/pipeline"
          element={!user ? <Login /> : <PipelineComponent />}
        />
      </Routes>
    </BrowserRouter>
  );
};

export default App;
