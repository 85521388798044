import React, { useState } from "react";
import axios from "axios";
import styles from "./PipelineComponent.module.css";
import JsonEditor from "../JsonEditor/JsonEditor.js";
import Footer from "../Footer/Footer.jsx";
import Header from "../Header/Header.js";
import Dialog from "../Dialog/Dialog.js";
import { formAttributes } from "./FormAttributes.js";
import DatabaseDiagram from "../DatabaseDiagram/DatabaseDiagram.js";
import EditDatabaseDialog from "../EditDatabaseDialog/EditDatabaseDialog.js";
import { useUser } from "../../UserContext.js";

const PipelineComponent = () => {
  const { isAuthorized } = useUser();
  const [apiStatus, setApiStatus] = useState(["", "", "", ""]);
  const [showEditor, setShowEditor] = useState(false);
  const [currentApiIndex, setCurrentApiIndex] = useState(null);
  const [dataTransformationState, setDataTransformationState] = useState("Run");
  const [classificationState, setClassificationState] = useState("Run");
  const [modelEvaluationState, setModelEvaluationState] = useState("Run");
  const [mlInferenceState, setMlInferenceState] = useState("Run");
  const [showDialog, setShowDialog] = useState(false);
  const [apiResponses, setApiResponses] = useState(["", "", "", ""]);
  const [dialogContent, setDialogContent] = useState("");
  const [showEditDialog, setShowEditDialog] = useState(false);
  const [formData, setFormData] = useState(formAttributes);

  const updateBoxStatus = (index, status) => {
    setApiStatus((prevStatus) => {
      const newStatus = [...prevStatus];
      newStatus[index] = status;
      return newStatus;
    });
  };

  const handleEditDatabaseClick = () => {
    setShowEditDialog(true);
  };

  const handleSaveDatasetName = (newName) => {
    setFormData((prevFormData) => {
      const updatedFormData = [...prevFormData];
      updatedFormData[0] = {
        ...updatedFormData[0],
        dataset_name: newName,
      };
      return updatedFormData;
    });
  };

  const callApi = async (index) => {
    const apiCalls = [
      callDataTransformation,
      callClassification,
      callModelEvaluation,
      callMlInference,
    ];
    const apiCall = apiCalls[index];
    if (!apiCall) return false;

    const formDataInstance = new FormData();
    Object.keys(formData[index]).forEach((key) => {
      if (typeof formData[index][key] === "object") {
        formDataInstance.append(key, JSON.stringify(formData[index][key]));
      } else {
        formDataInstance.append(key, formData[index][key]);
      }
    });

    try {
      const response = await apiCall(formDataInstance);
      if (response.status === 200) {
        setApiResponses((prevResponses) => {
          const newResponses = [...prevResponses];
          newResponses[index] = response.data;
          return newResponses;
        });
      }
      return response.status === 200;
    } catch (error) {
      console.error(`Error in API ${index + 1}:`, error);
      return false;
    }
  };

  const callDataTransformation = async (formDataInstance) => {
    return await axios.post(
      "https://pdm-market-539996738539.us-central1.run.app/data_transform_and_feature_eng_multi",
      formDataInstance
    );
  };

  const callClassification = async (formDataInstance) => {
    return await axios.post(
      "https://pdm-market-539996738539.us-central1.run.app/classification_ml_models",
      formDataInstance
    );
  };

  const callModelEvaluation = async (formDataInstance) => {
    return await axios.post(
      "https://pdm-market-539996738539.us-central1.run.app/model_evaluation",
      formDataInstance
    );
  };

  const callMlInference = async (formDataInstance) => {
    return await axios.post(
      "https://pdm-market-539996738539.us-central1.run.app/model_inference",
      formDataInstance
    );
  };

  const handleClick = async () => {
    if (isAuthorized === false) {
      alert("You are not authorized to run this.");
      return;
    }
    updateBoxStatus(0, "");
    updateBoxStatus(1, "");
    updateBoxStatus(2, "");

    setDataTransformationState("Running...");
    const firstSuccess = await callApi(0);
    if (firstSuccess) {
      updateBoxStatus(0, styles.green);
      setDataTransformationState("Run");
      setClassificationState("Running...");
      const secondSuccess = await callApi(1);
      if (secondSuccess) {
        updateBoxStatus(1, styles.green);
        setClassificationState("Run");

        setModelEvaluationState("Running...");
        const thirdSuccess = await callApi(2);
        if (thirdSuccess) {
          updateBoxStatus(2, styles.green);
        } else {
          updateBoxStatus(2, styles.red);
        }
        setModelEvaluationState("Run");
      } else {
        updateBoxStatus(1, styles.red);
        setClassificationState("Run");
      }
    } else {
      updateBoxStatus(0, styles.red);
      setDataTransformationState("Run");
    }
  };

  const handleIndividualClick = async (index) => {
    if (isAuthorized === false) {
      alert("You are not authorized to run this.");
      return;
    }
    updateBoxStatus(index, "");

    const result = await callApi(index);

    if (result) {
      updateBoxStatus(index, styles.green);
    } else {
      updateBoxStatus(index, styles.red);
    }
  };

  const openEditor = (index) => {
    setCurrentApiIndex(index);
    setShowEditor(true);
  };

  const openDialog = (index) => {
    setShowDialog(true);
    if (index === 2) {
      setDialogContent(apiResponses[index].result_df);
    } else if (index === 4) {
      setDialogContent(apiResponses[2].result_metrics_df);
    } else {
      setDialogContent(apiResponses[index]);
    }
  };

  const handleSave = (updatedData) => {
    setFormData((prevData) => {
      const newData = [...prevData];
      newData[currentApiIndex] = updatedData;
      return newData;
    });
  };

  return (
    <>
      <Header />
      <div className={styles.pipelineContainer}>
        <div className={styles.pipelineBoxContainer}>
          <DatabaseDiagram onClick={handleEditDatabaseClick} label="Dataset" />
          <span className={styles.arrow}>→</span>
          <div className={`${styles.box} ${apiStatus[0]}`}>
            Data Transform & Feature Engg.
            <div className={styles.buttonContainer}>
              <button onClick={() => openEditor(0)}>Configure</button>
              <button
                onClick={async () => {
                  setDataTransformationState("Running...");
                  await handleIndividualClick(0);
                  setDataTransformationState("Run");
                }}
              >
                {dataTransformationState}
              </button>
              {apiStatus[0] !== "" && (
                <button onClick={() => openDialog(0)}>Result</button>
              )}
            </div>
          </div>
          <span className={styles.arrow}>→</span>

          {/* Classification ML Models Box */}
          <div className={`${styles.box} ${apiStatus[1]}`}>
            ML Models
            <div className={styles.buttonContainer}>
              <button onClick={() => openEditor(1)}>Configure</button>
              <button
                onClick={async () => {
                  setClassificationState("Running...");
                  await handleIndividualClick(1);
                  setClassificationState("Run");
                }}
              >
                {classificationState}
              </button>
              {apiStatus[1] !== "" && (
                <button onClick={() => openDialog(1)}>Result</button>
              )}
            </div>
          </div>
          <span className={styles.arrow}>→</span>
          <div className={`${styles.box} ${apiStatus[2]}`}>
            Model Evaluation
            <div className={styles.buttonContainer}>
              <button onClick={() => openEditor(2)}>Configure</button>
              <button
                onClick={async () => {
                  setModelEvaluationState("Running...");
                  await handleIndividualClick(2);
                  setModelEvaluationState("Run");
                }}
              >
                {modelEvaluationState}
              </button>
              {apiStatus[2] !== "" && (
                <button onClick={() => openDialog(2)}>Result</button>
              )}
            </div>
          </div>
          {apiResponses[2]?.result_metrics_df && (
            <span className={styles.arrow}>→</span>
          )}
          {apiResponses[2]?.result_metrics_df && (
            <DatabaseDiagram onClick={() => openDialog(4)} label="Metrics" />
          )}
        </div>
        <button onClick={handleClick} className={styles.button}>
          Run All
        </button>

        <div className={styles.pipelineBoxContainer}>
          <DatabaseDiagram onClick={handleEditDatabaseClick} label="Dataset" />
          <span className={styles.arrow}>→</span>
          <div className={`${styles.box} ${apiStatus[3]}`}>
            ML Inference
            <div className={styles.buttonContainer}>
              <button onClick={() => openEditor(3)}>Configure</button>
              <button
                onClick={async () => {
                  setMlInferenceState("Running...");
                  await handleIndividualClick(3);
                  setMlInferenceState("Run");
                }}
              >
                {mlInferenceState}
              </button>
              {apiStatus[3] !== "" && (
                <button onClick={() => openDialog(3)}>Result</button>
              )}
            </div>
          </div>
        </div>
        {showEditor && (
          <JsonEditor
            initialData={formData[currentApiIndex]}
            onSave={handleSave}
            onClose={() => setShowEditor(false)}
          />
        )}
        {showDialog && (
          <Dialog
            content={dialogContent}
            onClose={() => setShowDialog(false)}
          />
        )}

        {showEditDialog && (
          <EditDatabaseDialog
            datasetName={formData[0].dataset_name}
            onSave={handleSaveDatasetName}
            onClose={() => setShowEditDialog(false)}
          />
        )}
      </div>
      <Footer />
    </>
  );
};

export default PipelineComponent;
